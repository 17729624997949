// Logos
import GuelphLogo from '../../images/gallivan/_uOfGuelphMain.png';
import KwantlenLogo from '../../images/gallivan/_kwantlenLogo.png';
import NaitsaLogo from '../../images/gallivan/_naitsaLogo.png';
import OldsLogo from '../../images/gallivan/_oldsLogo.png';
import LethbridgeLogo from '../../images/gallivan/_lethbridgeLogo.png';
import CamosunLogo from '../../images/gallivan/_camosunLogo.png';
import RidgetownLogo from '../../images/gallivan/_ridgetownLogo.png';
import TMSULogo from '../../images/custom/logos/tmsu-logo.png'
import GeorgianLogo from '../../images/gallivan/_georgianLogo.png';
import OntarioTechLogo from '../../images/gallivan/_ontarioTechLogo.png';
import BowValleyLogo from '../../images/gallivan/_bowValleyLogo.png';
import ConcordiaLogo from '../../images/gallivan/_concordiaLogo.png';
import GreatPlainsLogo from '../../images/gallivan/_greatPlainsLogo.png';
import NorquestLogo from '../../images/gallivan/norquestLogo.png';
import BCITLogo from '../../images/gallivan/_bcitLogo.png';
import SPSALogo from '../../images/gallivan/_spsaLogo.png';
import VSAOCLogo from '../../images/gallivan/_vsaocLogo.png';
import MacEwanLogo from '../../images/gallivan/_macewanLogo.png';
import UrsuLogo from '../../images/gallivan/ursuLogo.png';
import RedDeerLogo from '../../images/gallivan/_reddeerLogo.png';
import cbuLogo from '../../images/gallivan/_cbuLogo.png';
import purplecareLogo from '../../images/gallivan/purplecareLogo.png';
import boothLogo from '../../images/gallivan/boothLogo.png';
import borealLogo from '../../images/gallivan/boreal-logo.png';
import durhamLogo from '../../images/gallivan/durham-logo.png';
import nbccLogo from '../../images/gallivan/nbcc-logo.jpg';
import fanshaweLogo from '../../images/gallivan/fanshawe-logo.png';
import flemingLogo from '../../images/gallivan/fleming-logo.png';
import nsccLogo from '../../images/gallivan/nscc-logo.png';
import tyndaleLogo from '../../images/gallivan/tyndale-logo.png';
import cncLogo from '../../images/gallivan/cnc-logo.png';
import southeastLogo from '../../images/gallivan/southeast-logo.jpg';
import suncrestLogo from '../../images/custom/logos/suncrest-logo.png';

const gallivanSchools = [
  {
    key: 'uofguelphmain',
    title: 'University of Guelph CSA & GSA (Main Campus)',
    benefitsText: {
      __html:
        'As part of your student benefits, you get <b>80% discount on 10 one-on-one counselling sessions($500).</b>  On average, students use five sessions.',
    },
    myWellnessUrl: 'https://mystudentplan.ca/uofguelph/en/mywellness',
    insuranceInformationUrl: 'https://mystudentplan.ca/uofguelph/en/mybenefits',
    mentalHealthAssessmentUrl:
      'https://students.feelingbetternow.com/SzKP0kPoigk+OLdfSsdPPg==',
    inkblotUrl: 'https://www.inkblottherapy.com/uofguelphmain',
    inkblotSignUpUrl: 'https://app.inkblottherapy.com/signup?affiliation=UoGcg',
    logo: GuelphLogo,
    value: 'University of Guelph: CSA & GSA',
  },
  {
    key: 'kwantlen',
    title: 'Kwantlen Student Association',
    benefitsText: {
      __html:
        'As part of your student benefits, you get <b>80% discount on 10 one-on-one counselling sessions ($500).</b> On average, students use five sessions.',
    },
    myWellnessUrl: 'https://mystudentplan.ca/kwantlen/en/mywellness',
    insuranceInformationUrl: 'https://mystudentplan.ca/kwantlen/en/mybenefits',
    mentalHealthAssessmentUrl:
      'https://students.feelingbetternow.com/QPgnajjQ6R4AZXdImSfwAw==',
    inkblotUrl: 'https://www.inkblottherapy.com/kwantlen',
    inkblotSignUpUrl: 'https://app.inkblottherapy.com/signup?affiliation=KWP',
    logo: KwantlenLogo,
    value: 'Kwantlen Polytechnic University',
  },
  {
    key: 'naitsa',
    title: "Northern Alberta Institute of Technology Students' Association",
    benefitsText: {
      __html:
        'As part of your student benefits, you get <b>80% discount on 10 one-on-one counselling sessions ($500).</b> On average, students use five sessions.',
    },
    myWellnessUrl: 'https://mystudentplan.ca/nait/en/mywellness',
    insuranceInformationUrl: 'https://mystudentplan.ca/nait/en/mybenefits',
    mentalHealthAssessmentUrl:
      'https://students.feelingbetternow.com/u2B2trUKLMQamNDYzuOT+Q==',
    inkblotUrl: 'https://www.inkblottherapy.com/naitsa',
    inkblotSignUpUrl: 'https://app.inkblottherapy.com/signup?affiliation=NAIT',
    logo: NaitsaLogo,
    value: 'Northern Alberta Institute of Technology',
  },
  {
    key: 'olds',
    title: "Students' Association of Olds College",
    benefitsText: {
      __html:
        'As part of your student benefits, you get <b>80% discount on 7 one-on-one counselling sessions ($300).</b> On average, students use five sessions.',
    },
    myWellnessUrl: 'https://mystudentplan.ca/olds/en/mywellness',
    insuranceInformationUrl: 'https://mystudentplan.ca/olds/en/mybenefits',
    mentalHealthAssessmentUrl:
      'https://students.feelingbetternow.com/mtcayIsXQQex_NdahXT71Q==',
    inkblotUrl: 'https://www.inkblottherapy.com/olds',
    inkblotSignUpUrl: 'https://app.inkblottherapy.com/signup?affiliation=olds',
    logo: OldsLogo,
    value: 'Olds College',
  },
  {
    key: 'lethbridge',
    title: "Lethbridge College Students' Association",
    benefitsText: {
      __html:
        'As part of your student benefits, you get <b>80% discount on 10 one-on-one counselling sessions ($500).</b> On average, students use five sessions.',
    },
    myWellnessUrl: 'https://mystudentplan.ca/lethbridge/en/mywellness',
    insuranceInformationUrl:
      'https://mystudentplan.ca/lethbridge/en/mybenefits',
    mentalHealthAssessmentUrl:
      'https://students.feelingbetternow.com/47UZUNtcI1H1IHajDEj7yA==',
    inkblotUrl: 'https://www.inkblottherapy.com/lethbridge',
    inkblotSignUpUrl:
      'https://app.inkblottherapy.com/signup?affiliation=lethbridge',
    logo: LethbridgeLogo,
    value: 'Lethbridge College',
  },
  {
    key: 'camosun',
    title: 'Camosun College Student Society',
    benefitsText: {
      __html:
        'As part of your student benefits, you get <b>65% discount on 7 one-on-one counselling sessions ($300).</b> On average, students use five sessions.',
    },
    myWellnessUrl: 'https://mystudentplan.ca/camosun/en/mywellness',
    insuranceInformationUrl: 'https://mystudentplan.ca/camosun/en/mybenefits',
    mentalHealthAssessmentUrl:
      'https://students.feelingbetternow.com/xATlVr3XznfWgKq5Iv6cTA==',
    inkblotUrl: 'https://www.inkblottherapy.com/camosun',
    inkblotSignUpUrl:
      'https://app.inkblottherapy.com/signup?affiliation=camosun',
    logo: CamosunLogo,
    value: 'Camosun College',
  },
  {
    key: 'ridgetown',
    title: 'University of Guelph - Ridgetown Campus',
    benefitsText: {
      __html:
        'As part of your student benefits, you get <b>40% discount on 7 one-on-one counselling sessions ($300).</b> On average, students use five sessions.',
    },
    myWellnessUrl: 'https://mystudentplan.ca/ridgetown/en/mywellness',
    insuranceInformationUrl: 'https://mystudentplan.ca/ridgetown/en/mybenefits',
    mentalHealthAssessmentUrl:
      'https://students.feelingbetternow.com/pncZAl6vJZ_vm2WWe7aZUA==',
    inkblotUrl: 'https://www.inkblottherapy.com/ridgetown',
    inkblotSignUpUrl: 'https://app.inkblottherapy.com/signup?affiliation=UoGr',
    logo: RidgetownLogo,
    value: 'University of Guelph: Ridgetown',
  },
  {
    key: 'tmsu',
    title: "Toronto Metropolitan Students' Union",
    benefitsText: {
      __html:
        'As part of your student benefits, you get <b>10 one-on-one counselling sessions ($500).</b> On average, students use five sessions.',
    },
    myWellnessUrl: 'https://mystudentplan.ca/rsu/en/mywellness',
    insuranceInformationUrl: 'https://www.mystudentplan.ca/tmsu/en/mybenefits',
    mentalHealthAssessmentUrl:
      'https://www.feelingbetternow.com/tmsu',
    inkblotUrl: 'https://www.inkblottherapy.com/tmsu',
    inkblotSignUpUrl:
      'https://app.inkblottherapy.com/signup?affiliation=ryerson',
    logo: TMSULogo,
    value: 'Ryerson University',
    logoClass: 'small-logo',
  },
  {
    key: 'nscc',
    title: 'Nova Scotia Community College (NSCC)',
    benefitsText: {
      __html:
        'As part of your student benefits, you get <b>10 one-on-one counselling sessions ($500).</b> On average, students use five sessions.',
    },
    myWellnessUrl: 'https://mystudentplan.ca/nscc/en/mywellness',
    insuranceInformationUrl: 'https://www.mystudentplan.ca/nscc/en/mybenefits',
    mentalHealthAssessmentUrl:
      'https://students.feelingbetternow.com/XwozBFYi8lOzZAo3_BQ3hg==',
    inkblotUrl: 'https://www.inkblottherapy.com/nscc',
    inkblotSignUpUrl: 'https://app.inkblottherapy.com/signup?affiliation=nscc',
    logo: nsccLogo,
    value: 'Nova Scotia Community College',
  },
  {
    key: 'tyndale',
    title: 'Tyndale University',
    benefitsText: {
      __html:
        'As part of your student benefits, you get <b>10 one-on-one counselling sessions ($500).</b> On average, students use five sessions.',
    },
    myWellnessUrl: 'https://mystudentplan.ca/tyndale/en/mywellness',
    insuranceInformationUrl:
      'https://www.mystudentplan.ca/tyndale/en/mybenefits',
    mentalHealthAssessmentUrl: 'https://students.feelingbetternow.com/L2GxR_0F0oguf7lxB2ARog==',
    inkblotUrl: 'https://www.inkblottherapy.com/tyndale',
    inkblotSignUpUrl:
      'https://app.inkblottherapy.com/signup?affiliation=tyndale',
    logo: tyndaleLogo,
    value: 'Tyndale University',
  },
  {
    key: 'cnc',
    title: 'College of New Caledonia (CNC)',
    benefitsText: {
      __html:
        'As part of your student benefits, you get <b>10 one-on-one counselling sessions ($500).</b> On average, students use five sessions.',
    },
    myWellnessUrl: 'https://mystudentplan.ca/cnc/en/mywellness',
    insuranceInformationUrl: 'http://www.cncsu.ca/health/',
    mentalHealthAssessmentUrl: 'https://students.feelingbetternow.com/pfmhIH4bUKA91nEc23TitQ==',
    inkblotUrl: 'https://www.inkblottherapy.com/cnc',
    inkblotSignUpUrl: 'https://app.inkblottherapy.com/signup?affiliation=cnc',
    logo: cncLogo,
    value: 'College of New Caledonia',
  },
  {
    key: 'southeast',
    title: 'Southeast College',
    benefitsText: {
      __html:
        'As part of your student benefits, you get <b>10 one-on-one counselling sessions ($500).</b> On average, students use five sessions.',
    },
    myWellnessUrl: 'https://mystudentplan.ca/southeast/en/mywellness',
    insuranceInformationUrl:
      'https://www.mystudentplan.ca/southeast/en/mybenefits',
    mentalHealthAssessmentUrl: 'https://students.feelingbetternow.com/j810SCH4JHWHND9Jf+tLng==',
    inkblotUrl: 'https://www.inkblottherapy.com/southeast',
    inkblotSignUpUrl:
      'https://app.inkblottherapy.com/signup?affiliation=southeast',
    logo: southeastLogo,
    value: 'Southeast College',
  },
  {
    key: 'georgian',
    title: 'Georgian College',
    benefitsText: {
      __html:
        'As part of your student benefits, you get <b>80% discount on 7 one-on-one counselling sessions ($300).</b> On average, students use five sessions.',
    },
    myWellnessUrl: 'https://mystudentplan.ca/georgian/en/mywellness',
    insuranceInformationUrl: 'https://mystudentplan.ca/georgian/en/mybenefits',
    mentalHealthAssessmentUrl:
      'https://students.feelingbetternow.com/yprcJ7lTw_nwW5ycDSMY6A==',
    inkblotUrl: 'https://www.inkblottherapy.com/georgian',
    inkblotSignUpUrl:
      'https://app.inkblottherapy.com/signup?affiliation=georgian',
    logo: GeorgianLogo,
    value: 'Georgian College',
  },
  {
    key: 'ontario_tech',
    title: 'Ontario Tech',
    benefitsText: {
      __html:
        'As part of your student benefits, you get <b>80% discount on 7 one-on-one counselling sessions ($300).</b> On average, students use five sessions.',
    },
    myWellnessUrl: 'http://www.mystudentplan.ca/ontario-tech/en/mywellness',
    insuranceInformationUrl:
      'https://www.mystudentplan.ca/ontario-tech/en/mybenefits',
    mentalHealthAssessmentUrl:
      'https://students.feelingbetternow.com/sJ9sBRt5y4rtYVoTEA6okA==',
    inkblotUrl: 'https://www.inkblottherapy.com/ontario-tech',
    inkblotSignUpUrl:
      'https://app.inkblottherapy.com/signup?affiliation=ontario_tech',
    logo: OntarioTechLogo,
    value: 'Ontario Tech',
  },
  {
    key: 'bowvalley',
    title: 'Bow Valley College',
    benefitsText: {
      __html:
        'As part of your student benefits, you get <b>40% discount on 7 one-on-one counselling sessions ($300).</b> On average, students use five sessions.',
    },
    myWellnessUrl: 'http://www.mystudentplan.ca/bowvalley/en/mywellness',
    insuranceInformationUrl:
      'https://www.mystudentplan.ca/bowvalley/en/mybenefits',
    mentalHealthAssessmentUrl:
      'https://students.feelingbetternow.com/GRNX23e82X+uFRBsXh_zfA==',
    inkblotUrl: 'https://www.inkblottherapy.com/bowvalley',
    inkblotSignUpUrl:
      'https://app.inkblottherapy.com/signup?affiliation=bow_valley_college',
    logo: BowValleyLogo,
    value: 'Bow Valley College',
  },
  {
    key: 'concordia',
    title: 'Concordia University of Edmonton',
    benefitsText: {
      __html:
        'As part of your student benefits, you get <b>80% discount on 7 one-on-one counselling sessions ($300).</b>On average, students use five sessions.',
    },
    myWellnessUrl: 'http://www.mystudentplan.ca/csa/en/mywellness',
    insuranceInformationUrl: 'https://www.mystudentplan.ca/csa/en/mybenefits',
    mentalHealthAssessmentUrl:
      'https://students.feelingbetternow.com/L6_cPFOP9hkUWpzCoBHZUA==',
    inkblotUrl: 'https://www.inkblottherapy.com/concordia',
    inkblotSignUpUrl:
      'https://app.inkblottherapy.com/signup?affiliation=concordia_university_of_edmonton',
    logo: ConcordiaLogo,
    value: 'Concordia University of Edmonton',
  },
  {
    key: 'greatplains',
    title: 'Great Plains College',
    benefitsText: {
      __html:
        'Great Plains College is supporting your mental health and will cover the cost of all of your sessions on Inkblot!',
    },
    myWellnessUrl: 'https://www.mystudentplan.ca/gpc/en/mywellness',
    insuranceInformationUrl: 'https://www.mystudentplan.ca/gpc/en/mybenefits',
    mentalHealthAssessmentUrl:
      'https://students.feelingbetternow.com/bl2gm0CX4aSekN3KXbevNA==',
    inkblotUrl: 'https://www.inkblottherapy.com/greatplains',
    inkblotSignUpUrl:
      'https://app.inkblottherapy.com/signup?affiliation=great_plains_college',
    logo: GreatPlainsLogo,
    value: 'Great Plains College',
  },
  {
    key: 'suncrest',
    title: 'Suncrest College',
    benefitsText: {
      __html:
        'As part of your student benefits, you get <b>45% discount on 9 one-on-one counselling sessions ($400).</b> On average, students use five sessions.',
    },
    insuranceInformationUrl: 
      "https://www.mystudentplan.ca/suncrest",
    mentalHealthAssessmentUrl: 
      "http://www.feelingbetternow.com/suncrest",
    myWellnessUrl:
      'https://www.mystudentplan.ca/suncrest/en/mywellness',
    inkblotUrl: 'https://www.inkblottherapy.com/suncrest',
    inkblotSignUpUrl:
      'https://app.inkblottherapy.com/signup?affiliation=suncrest',
    logo: suncrestLogo,
    value: 'Suncrest College'
  },
  {
    key: 'norquest',
    title: 'Norquest College',
    benefitsText: {
      __html:
        'As part of your student benefits, you get 7 <b>one-on-one counselling sessions ($300).</b> On average, students use five sessions.',
    },
    myWellnessUrl: 'http://www.mystudentplan.ca/norquest/en/mywellness',
    insuranceInformationUrl:
      'https://www.mystudentplan.ca/norquest/en/mybenefits',
    mentalHealthAssessmentUrl:
      'https://students.feelingbetternow.com/Q0XU8hslQJPcYzfOiuaLVA==',
    inkblotUrl: 'https://www.inkblottherapy.com/norquest',
    inkblotSignUpUrl:
      'https://app.inkblottherapy.com/signup?affiliation=norquest',
    logo: NorquestLogo,
    value: 'Norquest College',
  },
  {
    key: 'bcit',
    title: 'BCIT',
    benefitsText: {
      __html:
        'As part of your student benefits, you get 80% discount on 9 <b>one-on-one counselling sessions ($400).</b> On average, students use five sessions.',
    },
    myWellnessUrl: 'http://www.mystudentplan.ca/bcit/en/mywellness',
    insuranceInformationUrl: 'https://www.mystudentplan.ca/bcit/en/mybenefits',
    mentalHealthAssessmentUrl:
      'https://students.feelingbetternow.com/s43IA4LBZepIoPs3hYtrFw==',
    inkblotUrl: 'https://www.inkblottherapy.com/bcit',
    inkblotSignUpUrl: 'https://app.inkblottherapy.com/signup?affiliation=bcit',
    logo: BCITLogo,
    value: 'BCIT',
  },
  {
    key: 'saskpoly',
    title: 'Saskatchewan Polytechnic',
    benefitsText: {
      __html:
        'As part of your student benefits, you get <b>7 one-on-one counselling sessions ($300).</b> On average, students use five sessions.',
    },
    myWellnessUrl: 'https://www.mystudentplan.ca/saskpolytech/en/mywellness',
    insuranceInformationUrl:
      'https://www.mystudentplan.ca/saskpolytech/en/mybenefits',
    mentalHealthAssessmentUrl:
      'https://students.feelingbetternow.com/Mzq30Hr+1THQH42v2AGKkg==',
    inkblotUrl: 'https://www.inkblottherapy.com/saskpoly',
    inkblotSignUpUrl:
      'https://app.inkblottherapy.com/signup?affiliation=saskpoly',
    logo: SPSALogo,
    value: 'Saskatchewan Polytechnic',
  },
  {
    key: 'okanagan',
    title: 'Okanagan College',
    benefitsText: {
      __html:
        'As part of your student benefits, you get <b>40% discount on 7 one-on-one counselling sessions ($300). </b>On average, students use five sessions to start to feel their best.',
    },

    myWellnessUrl: 'https://www.mystudentplan.ca/okanagan/en/mywellness',
    insuranceInformationUrl:
      'https://www.mystudentplan.ca/okanagan/en/mybenefits',
    mentalHealthAssessmentUrl:
      'https://students.feelingbetternow.com/NzpJi3E6xhantJ_TPtE1nA==',
    inkblotUrl: 'https://www.inkblottherapy.com/okanagan',
    inkblotSignUpUrl:
      'https://app.inkblottherapy.com/signup?affiliation=okanagan',
    logo: VSAOCLogo,
    value: 'Okanagan College',
  },
  {
    key: 'macewan',
    title: "Students' Association of MacEwan University",
    benefitsText: {
      __html:
        'As part of your student benefits, you get <b>80% discount on 10 one-on-one counselling sessions ($500).</b>On average, students use five sessions.',
    },
    myWellnessUrl: 'https://www.mystudentplan.ca/macewan/en/mywellness',
    insuranceInformationUrl: 'https://www.mystudentplan.ca/macewan/en/home',
    mentalHealthAssessmentUrl:
      'https://students.feelingbetternow.com/UH9lwKLpYlsqcL6LFMLUCA==',
    inkblotUrl: 'https://www.inkblottherapy.com/macewan',
    inkblotSignUpUrl:
      'https://app.inkblottherapy.com/signup?affiliation=macewan',
    logo: MacEwanLogo,
    value: 'MacEwan University',
  },
  {
    key: 'ursu',
    title: 'URSU',
    benefitsText: {
      __html:
        'As part of your student benefits, you get <b>80% discount on 10 one-on-one counselling sessions ($500).</b> On average, students use five sessions.',
    },
    myWellnessUrl: 'https://www.mystudentplan.ca/ursu/en/mywellness',
    insuranceInformationUrl: 'https://ursu.ca/services/health-dental-plan/',
    mentalHealthAssessmentUrl:
      'https://students.feelingbetternow.com/7k+nSBM6c4r+S1WzIUCd9A==',
    inkblotUrl: 'https://www.inkblottherapy.com/ursu',
    inkblotSignUpUrl: 'https://app.inkblottherapy.com/signup?affiliation=ursu',
    logo: UrsuLogo,
    value: 'URSU',
  },
  {
    key: 'reddeer',
    title: 'Red Deer College',
    benefitsText: {
      __html:
        'As part of your student benefits, you get <b>80% discount on 10 one-on-one counselling sessions ($500).</b> On average, students use five sessions.',
    },
    myWellnessUrl: 'https://www.mystudentplan.ca/reddeer/en/mywellness',
    insuranceInformationUrl:
      'https://www.mystudentplan.ca/reddeer/en/mybenefits',
    mentalHealthAssessmentUrl:
      'https://students.feelingbetternow.com/a+LDNOGCN1oaOWVW0Qd9AA==',
    inkblotUrl: 'https://www.inkblottherapy.com/reddeer',
    inkblotSignUpUrl:
      'https://app.inkblottherapy.com/signup?affiliation=reddeer',
    logo: RedDeerLogo,
    value: 'Red Deer College',
  },
  {
    key: 'cbu',
    title: 'Cape Breton University',
    benefitsText: {
      __html:
        'As part of your student benefits, you get <b>80% discount on 10 one-on-one counselling sessions ($500).</b> On average, students use five sessions.',
    },
    myWellnessUrl: 'https://mystudentplan.ca/cbusu/en/mywellness',
    insuranceInformationUrl: 'https://studentbenefits.ca/my-coverage',
    mentalHealthAssessmentUrl:
      'https://students.feelingbetternow.com/TRN8_KDdhyVVBqIAlhHEyA==',
    inkblotUrl: 'www.inkblottherapy.com/cbu',
    inkblotSignUpUrl: 'https://app.inkblottherapy.com/signup?affiliation=cbu',
    logo: cbuLogo,
    value: 'Cape Breton University',
  },
  {
    key: 'western-purplecare',
    title: 'Western PurpleCare',
    benefitsText: {
      __html:
        'As part of your student benefits, you get sixteen one-on-one counselling sessions ($750). On average, students use five sessions.',
    },
    myWellnessUrl: 'https://www.mystudentplan.ca/purplecare/en/mywellness',
    insuranceInformationUrl: 'https://www.mystudentplan.ca/purplecare/en/studentplan',
    mentalHealthAssessmentUrl:
      'https://students.feelingbetternow.com/K9lsXSIqOpIrrUoYTXeJrHVksq8hTRb99v8gWGsvOYc=',
    inkblotUrl: 'www.inkblottherapy.com/western-purplecare',
    inkblotSignUpUrl:
      'https://app.inkblottherapy.com/signup?affiliation=western-purplecare',
    logo: purplecareLogo,
    value: 'Western PurpleCare',
  },
  {
    key: 'booth',
    title: 'Booth University College',
    benefitsText: {
      __html:
        'As part of your student benefits, you get 40% discount on 7 one-on-one counselling sessions ($300). On average, students use five sessions.',
    },
    myWellnessUrl: 'https://mystudentplan.ca/booth/en/mywellness',
    insuranceInformationUrl: 'https://www.mystudentplan.ca/booth/en/mybenefits',
    mentalHealthAssessmentUrl:
      'https://students.feelingbetternow.com/LGHdJeGl+Nz_OMH2bMv9Ew==',
    inkblotUrl: 'www.inkblottherapy.com/booth',
    inkblotSignUpUrl: 'https://app.inkblottherapy.com/signup?affiliation=booth',
    logo: boothLogo,
    value: 'Booth University College',
  },
  {
    key: 'nbcc',
    title: 'New Brunswick Community College',
    insuranceInformationUrl: 'https://www.mystudentplan.ca/nbcc/en/mybenefits',
    mentalHealthAssessmentUrl: 'https://students.feelingbetternow.com/_KA3dTBBP+crSFNRaYbRVw==',
    inkblotUrl: 'www.inkblottherapy.com/nbcc',
    logo: nbccLogo,
    value: 'New Brunswick Community College',
  },
  {
    key: 'boreal',
    title: 'Collège Boréal International',
    insuranceInformationUrl: 'https://morcare.ca/home/56-college-boreal',
    mentalHealthAssessmentUrl: 'https://students.feelingbetternow.com/ONJEu8JpAUZkrf0D0vdlhw==',
    inkblotUrl: 'www.inkblottherapy.com/boreal',
    logo: borealLogo,
    value: 'Collège Boréal International',
  },
  {
    key: 'durham',
    title: 'Durham College International',
    insuranceInformationUrl:
      'https://studentvip.ca/frmPage.aspx?school_page_id=e2d18d12-fb21-405a-a8bf-7e310d8ff7ae',
    mentalHealthAssessmentUrl: 'https://students.feelingbetternow.com/sqYd5J4b7IkwSjoEGfy2lw==',
    inkblotUrl: 'www.inkblottherapy.com/durham',
    logo: durhamLogo,
    value: 'Durham College International',
  },
  {
    key: 'fanshawe',
    title: 'Fanshawe College International',
    insuranceInformationUrl:
      'https://morcare.ca/home/52-fanshawe-college-international-students-',
    mentalHealthAssessmentUrl: 'https://students.feelingbetternow.com/Gk3bKsAlNjYZAAnho97+qg==',
    inkblotUrl: 'www.inkblottherapy.com/fanshawe',
    logo: fanshaweLogo,
    value: 'Fanshawe College International',
  },
  {
    key: 'fleming',
    title: 'Fleming College International',
    insuranceInformationUrl:
      'https://morcare.ca/home/52-fleming-college-international-students-',
    mentalHealthAssessmentUrl: 'https://students.feelingbetternow.com/fTrEiRw3yauwXa9QTQIlig==',
    inkblotUrl: 'www.inkblottherapy.com/fleming',
    logo: flemingLogo,
    value: 'fleming College International',
  },
  {
    key: 'nscce-campus',
    title: 'Nova Scotia Community College E-Campus',
    insuranceInformationUrl: 'https://www.mystudentplan.ca/nscc/en/home',
    mentalHealthAssessmentUrl:
      'https://students.feelingbetternow.com/XwozBFYi8lOzZAo3_BQ3hg==',
    inkblotUrl: 'https://www.inkblottherapy.com/nscce-campus',
    logo: nsccLogo,
    value: 'Nova Scotia Community College E-Campus',
  },
];

export default gallivanSchools;
